<template>
  <div class="updateResume">
    <div class="content">
      <div class="row" @click="goNextpage('upload')">
        <p class="tit">上传附件简历</p>
        <div class="detail">
          <p>
            <span>建议PDF文件，大小不超过20M</span>
            <span>也支持docx、doc、jpg、png</span>
          </p>
          <img src="../../assets/html/upload.png" alt="" />
        </div>
      </div>
      <div class="row" @click="goNextpage('edit')">
        <p class="tit">在线编辑简历</p>
        <div class="detail">
          <p>
            <span>在线编辑你的基本资料及经历</span>
          </p>
          <img src="../../assets/html/edit.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // resetRem();
  },
  methods: {
    goNextpage(type) {
      if (type == "upload") {
        this.$router.push({
          path: "/uploadFile",
          query: {
            rid: this.$route.query.rid,
          },
        });
      } else {
        this.$router.push({
          path: "/onlineEdit",
          query: {
            rid: this.$route.query.rid,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.updateResume {
  margin: 0 auto;
  max-width: 750px;
  background: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .content {
    padding: 20px;
    .row {
      background: #fff;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px 6px rgba(51, 51, 51, 0.05);
      .tit {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #333;
      }
      .detail {
        display: flex;
        justify-content: space-between;
        p {
          span {
            display: block;
            font-size: 14px;
            color: #999999;
            line-height: 1.5;
          }
        }
        img {
          width: 46px;
          height: 36px;
        }
      }
    }
  }
}
</style>